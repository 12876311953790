html, body, #root {
  height:100%;
  margin: 0;
  padding: 0;

  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}

body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

#root > div {
  flex: 1 !important;
}

.web-overlay {
  flex: 1;
}

.web-overlay:empty {
  flex: 0;
}

.updater-container {
  display: none;
  top: 0 !important;
}
.updater-container.updating {
  display: block;
}

.webvideo-player{
  background-color: black;
}


/* Modals */
.modal_overlay,
.services-exporter-modal-container,
.services-creator-modal-container,
.session-name-editor-modal-container,
.modal_overlay_usb_exporter,
.chroma-settings-container {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.85);
  padding: 40px;
}

.services-exporter > div{
  max-height: 100%;
  max-width: 100%;
}

.services-creator-modal-container .ReactModal__Content,
.services-creator-modal-container .ReactModal__Content > div,
.services-creator-modal-container .ReactModal__Content > div > div,
.services-creator-modal-container .ReactModal__Content > div > div > div,
.services-creator-modal-container .ReactModal__Content > div > div > div
.services-creator,
.services-creator > div,
.services-exporter-modal-container .ReactModal__Content,
.services-exporter,
.services-exporter > div {
  height: 100%;
  max-height: 100%;
  max-width: 100%;
}

.services-creator > div, 
.services-creator > div > div > div {
  width: 100% ! important;
}

.services-creator > div > div:first-child {
  width: 100% !important;
}
.services-creator > div > div > div:first-child {
  background-size: contain;
}

.services-creator > div > div > img {
  display: none;
}

.session-name-editor-modal-container .ReactModal__Content div {
  border: 0;
}

.option-publishing-container-active-profile-row {
  z-index: 999;
}

.option-publishing-container-active-profile > div > div:last-child > div:last-child {
  align-self: flex-end !important;
}

.option-publishing-container-active-profile > div > div:last-child > div:last-child > div > div {
  right: 0 !important;
  top: 20px !important;
  z-index: 999;
}

.connector-type-button > div,
.connector-type-button > div,
.connector-type-button > div > div:first-child,
.connector-type-button > div > div > div {
  width: 100%;
  height: 100%;
  background-size: contain;
}

.connector-type-button {
  padding: 20px 0;
}

.connector-type-button > div {
  height: inherit;
  padding: 20px;
  margin: 0;
}

.connector-type-button img {
  display: none;
}

.room-configurator-container  > div > div > div > div, 
.joystick-container > div > div > div {
  width: 100%;
}

.main-screen-bottom-menu-slide-menu > div {
  height: 100%;
}

.main-screen-bottom-menu-slide-menu .closing-expanding-button > div {
  align-self: center;
}

.option-panel-container > div > div > div {
  width: 100%;
  height: 100%;
}
.option-panel-container div {
  flex-shrink: 0;
}

.option-panel-container .iconed-button-touchable {
  align-self: center;
  text-align: center;
}

.option-panel-container .iconed-button-touchable .iconed-button-image {
  width: 100% !important;
}

.options-about-container > div:first-child > div:first-child {
  background-size: contain;
}

.options-about-container > div:first-child img {
  display: none;
}

.options-about-container-buttons {
  display: block;
}

.option-publishing-container-legacy .iconed-button-image {
  width: 100%;
}


.option-audio-container-legacy .iconed-button-image {
  width: 100% !important;
  margin-top: 30px;
}

.modal-content-usb-exporter {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.85);
  padding: 40px;
  display: flex;
  justify-content: center;
}

.modal-content-usb-exporter > div {
  width: 100%;
  padding: 40px;
}

.modal-content-usb-exporter > div > div:first-child {
  padding: 10px;
  text-align: center;
}

.modal-content-usb-exporter > div > div:last-child {
  margin: 20px 0;
  padding: 0 10px;
  text-align: center;
  align-self: center;
  background-color: #AAA;
  color: #000;
  border-radius: 10px;
}

.modal-content-usb-exporter > div > div:last-child div {
  color: #000;
  text-transform: none;
  font-weight: normal;
}

.modal-content-usb-exporter .usb-enties {
  background-color: #FFFFFF;
  height: 60%;
}

.modal-content-usb-exporter .usb-entry {
  padding: 15px;
  border-bottom: 1px solid #DDD;
}

.usb-importer ,
.usb-importer > div {
  height: 100%;
}

.chroma-settings-container > .ReactModal__Content,
.chroma-settings-container > .ReactModal__Content > div {
  height: 100%;
}

.sketch-picker {
  height: 100% !important;
  width: inherit !important;
  box-shadow: none !important;
  border-radius: 0 !important;
  border: none !important;
  background-color: #000000AA !important;
  padding: 0 !important;
  display: flex;
  flex-direction: column;
}

.sketch-picker > div:first-child {
  flex: 1;
  padding-bottom: 0 !important;
}

.sketch-picker > div:nth-child(2) {
  padding: 0 18px;
}

.sketch-picker > div:nth-child(2) > div > div {
  height: 20px !important;
}

.sketch-picker > div:nth-child(3) > div {
  padding: 0 25px;
}

.sketch-picker input {
  background-color: #333333;
  border: 0 !important;
  box-shadow: none !important;
  color: #FFF;
  padding: 6px !important;
  align-self: center;
  width: 100% !important;
}

.sketch-picker input,
.sketch-picker span {
  font-size: 15px !important;
  color: #FFF !important;
  font-family: Arial, Helvetica, sans-serif;
}

.sketch-picker > div:last-child {
  border-top-color: #333333 !important;
  margin: 0 !important;
  padding: 20px 0 !important;
  justify-content: space-evenly;
}

.sketch-picker > div:nth-child(3) > div > div {
  display: flex;
  flex-direction: column;
}

.sketch-picker > div:last-child > div {
  height: 25px !important;
  width: 25px !important;
}

.Toastify {
  position: absolute;
  z-index: 999;
}

.jsmpeg-canvas-wrapper {
  display: flex;
  align-content: center;
  justify-content: center;
  max-width: 100%;
  max-height: 100%;
}

.jsmpeg-canvas {
  width: 100%;
  height: 100%;
  max-height: 100%;
  max-width: 100%;
}


.main-screen > div:first-child > div,
.main-screen > div:first-child > div > div {
  align-items: center;
}